import React, { useEffect, useMemo, useState } from "react";

import { Modal } from "@material-ui/core";
import { AnnouncementNotification } from "@remar/shared/dist/components/Notifications";
import useSearchParams from "@remar/shared/dist/hooks/useSearchParams";
import useToastNotifications from "@remar/shared/dist/services/useToastNotifications";
import { getCurrentSubscription } from "@remar/shared/dist/utils/subscriptionUtils";
import { isToday } from "date-fns";
import { useUpgradeForm } from "hooks/useUpgradeForm";
import { useHistory } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "store";
import {
	applyValidatedCoupon,
	getFullState,
	getUserData,
	setPaymentDiscount,
	setSelectSubModal,
	setUpgradeModal
} from "store/features/Auth/authSlice";
import { getSubscriptionInfo, getUserInfo } from "store/features/MyAccount/myAccountSlice";
import {
	dismissAnnouncement,
	markBannerOrAnnouncementAsRead,
	selectAnnouncementNotifications,
	setBannerActionLoading,
	setBannerData
} from "store/features/notifications/notifications.slice";

import { routes } from "core/constants";

import StudentForm from "modules/Course/StudentForm";

import ChangeSubscription from "modules/MyAccount/ChangeSubscription";

import UpgradeSubscription from "modules/MyAccount/UpgradeSubscription";

import { GLOBAL_CONSTANTS } from "../../../../constants";

import BaseLayout from "../../BaseLayout";

const StudentModal = ({ modal, closeModal }) => (
	<Modal
		style={{
			alignItems: "center",
			justifyContent: "center",
			display: "flex"
		}}
		disableEnforceFocus
		disableAutoFocus
		open={!!modal}
	>
		<StudentForm closeModal={closeModal} />
	</Modal>
);
const GlobalWrapper = ({ children }: { children: React.ReactNode }) => {
	const { pushToastNotification, dismissToastNotification } = useToastNotifications();
	const announcementNotifications = useAppSelector(selectAnnouncementNotifications);
	const dispatch = useAppDispatch();
	const history = useHistory();
	const params = useSearchParams();
	const shouldOpenUpgradeModal = params.get("upgrade");
	const showSchoolModal = GLOBAL_CONSTANTS.TOGGLE_SCHOOL_MODAL;

	const {
		user,
		paymentDiscount,
		isLoggedIn,
		inactiveSubscription: { isRenewEnabled },
		upgradeModal,
		selectSubModal,
		qbAvailableOfferings
	} = useAppSelector(getFullState);

	const [studentModal, setStudentModal] = useState(user && showSchoolModal && (!user.schoolId || !user.hasTakenIntro));
	const isTrial = useMemo(() => getCurrentSubscription(user?.subscriptions).type?.isTrial, [user]);

	const onUpgradeSubmit = () => {
		dispatch(setUpgradeModal(false));
		dispatch(setSelectSubModal(false));
		dispatch(getUserData());
		dispatch(getUserInfo());
		dispatch(setPaymentDiscount(null));
		dispatch(getSubscriptionInfo());
		if (!!qbAvailableOfferings) {
			dispatch(setBannerData({ showBanner: false }));
		}
		history.push(`${routes.course.getPath()}?refetch=true`);
	};

	const { closeModal: closeUpgradeModel, ...upgradeFormProps } = useUpgradeForm({
		onSubmit: onUpgradeSubmit,
		isRenewSubscription: isRenewEnabled
	});

	useEffect(() => {
		if (announcementNotifications.items?.length) {
			pushToastNotification(
				<AnnouncementNotification
					applyCoupon={c => {
						dispatch(applyValidatedCoupon(c));
						dispatch(dismissAnnouncement(c.dismissId));
						dispatch(markBannerOrAnnouncementAsRead(c.dismissId));
					}}
					dismiss={id => {
						dispatch(dismissAnnouncement(id));
						dispatch(markBannerOrAnnouncementAsRead(id));
					}}
					announcements={announcementNotifications.items}
				/>,
				{
					toastId: "announcement",
					autoClose: false,
					closeOnClick: false
				}
			);
		} else {
			dismissToastNotification("announcement");
		}
	}, [announcementNotifications.items, dismissToastNotification, dispatch, pushToastNotification]);

	useEffect(() => {
		if (user && showSchoolModal) {
			setStudentModal((isLoggedIn && !user.schoolId) || !!paymentDiscount || user.hasTakenIntro === false);
		}
	}, [showSchoolModal, user, paymentDiscount, isLoggedIn]);

	useEffect(() => {
		if (Boolean(shouldOpenUpgradeModal) && isTrial) {
			dispatch(setUpgradeModal(true));
		}
	}, [shouldOpenUpgradeModal, isTrial, dispatch]);

	const closeModal = () => setStudentModal(false);
	if (isLoggedIn) {
		return (
			<BaseLayout>
				{children}
				<StudentModal modal={studentModal} closeModal={closeModal} />
				<Modal open={upgradeModal! || selectSubModal!} onClose={closeUpgradeModel}>
					<UpgradeSubscription {...upgradeFormProps} closeModal={closeUpgradeModel} />
				</Modal>
				<ChangeSubscription
					onCloseEnd={() => dispatch(setBannerActionLoading(false))}
					closeSideEffect={d => {
						if (isToday(d!)) {
							dispatch(
								setBannerData({
									showBanner: false,
									expiresIn: null,
									isTrial: false,
									isActionLoading: false
								})
							);
						}
					}}
				/>
			</BaseLayout>
		);
	}
	return (
		<>
			{children}
			<StudentModal modal={studentModal} closeModal={closeModal} />
		</>
	);
};

export default GlobalWrapper;
