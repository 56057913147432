import React, { useEffect, useMemo, useRef, useState } from "react";

import { Box, CircularProgress, Typography } from "@material-ui/core";
import { TermsAndConditions } from "@remar/shared/dist/modals/TermsAndConditions";
import { Course, CourseChapter } from "@remar/shared/dist/models";
import {
	ChapterImg,
	ChapterImgContainer,
	ChaptersRow,
	LessonSwiperWrapper,
	SectionTitle
} from "@remar/shared/dist/styles/swiper";
import { formatUSD } from "@remar/shared/dist/utils/formatUSD";
import { useStripe } from "@stripe/react-stripe-js";

import { useHistory, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import {
	confirmAcceptedTermsAndConditions,
	selectCurrentlyAllowedFullCourses,
	selectCurrentlyAllowedTrialCourses,
	selectIsTrial,
	selectUser
} from "store/features/Auth/authSlice";

import { getFullState as getFullBillingState } from "store/features/Billing/BillingSlice";
import {
	fetchIntroLessons,
	loadTrialCourse,
	resetChapterUnlock,
	selectFullState,
	selectTrialCoursesInfo,
	setLoading,
	unlockChapter
} from "store/features/Course/courseSlice";
import { getFullState, getLastViewedSectionId } from "store/features/Lesson/lesson.slice";
import SwiperCore, { Mousewheel, Navigation } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";

import CourseLessonItem from "./CourseLessonItem";
import CoursePreviewBackground from "./CoursePreviewBackground";

import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import UnlockChapterModal from "./Modals/UnlockChapter";
import RemainingPeriod from "./Modals/components/RemainingPeriod";
import { LockIcon, OrderSummaryWrapper } from "./style";

import OneTimePayment from "../Billings/OneTimePayment";

SwiperCore.use([Mousewheel, Navigation]);

type TrialCoursesInfoSelection = { trialCourses: Course[]; isLoading: boolean };
type AllowedTrialCoursesSelection = Course[] | undefined;
type AllowedFullCoursesSelection = Course[] | undefined;

interface NavOptions {
	prevEl: HTMLDivElement | null;
	nextEl: HTMLDivElement | null;
}

const LessonsList = ({ section, sectionLessons, latestLesson }) => {
	const nextEl = useRef(null);
	const prevEl = useRef(null);

	return (
		<>
			<SectionTitle key={`name-${section.id}`}>{section.name}</SectionTitle>
			<LessonSwiperWrapper key={`lessons-${section.id}`}>
				<Swiper
					navigation={{
						prevEl: prevEl.current,
						nextEl: nextEl.current
					}}
					slidesPerView="auto"
					spaceBetween={0}
					mousewheel={{ releaseOnEdges: true }}
					virtualTranslate
					className="popout"
					onSetTranslate={(swiper, delta) => {
						swiper.$wrapperEl.css("margin-left", delta + "px");
					}}
					onBeforeInit={swiper => {
						if (swiper?.params?.navigation) {
							(swiper.params.navigation as NavOptions).prevEl = prevEl.current;
							(swiper.params.navigation as NavOptions).nextEl = nextEl.current;
						}
					}}
				>
					{sectionLessons.map((sectionLesson, i) => (
						<SwiperSlide key={sectionLesson.id}>
							<CourseLessonItem
								number={i + 1}
								section={section}
								sectionLesson={sectionLesson}
								latestLesson={latestLesson}
							/>
						</SwiperSlide>
					))}
					<span slot="wrapper-end">
						<div className="swiper-button-prev" ref={prevEl}></div>
						<div className="swiper-button-next" ref={nextEl}></div>
						<div className="swiper-button-next-overlay"></div>
						<div className="swiper-button-prev-overlay"></div>
					</span>
				</Swiper>
			</LessonSwiperWrapper>
		</>
	);
};
const UnlockChapterOrderSummary = ({ items }: { items: { id: number; name: string; price: number }[] }) => (
	<OrderSummaryWrapper>
		{items.map(item => (
			<Box className={"order-item"} key={item.id}>
				<Typography>{item.name}</Typography>
				<Typography>{formatUSD(item.price)}</Typography>
			</Box>
		))}
		<Box mt={4} display={"flex"} flexDirection={"column"} alignItems={"flex-end"}>
			<Typography>Your Total</Typography>
			<Typography variant={"h5"}>{formatUSD(items.reduce((prev, curr) => prev + curr.price, 0))}</Typography>
		</Box>
	</OrderSummaryWrapper>
);
const CourseView = () => {
	const dispatch = useAppDispatch();
	const history = useHistory();
	const stripe = useStripe();
	const { pathname, search } = useLocation();

	const chapterId = useMemo(() => new URLSearchParams(search).get("chapterId"), [search]);

	const { lastViewedSectionId } = useAppSelector(getFullState);
	const { introLessonsLoading, unlockLoading, isChapterSuccessfullyUnlocked } = useAppSelector(selectFullState);
	const { trialCourses, isLoading }: TrialCoursesInfoSelection = useAppSelector(selectTrialCoursesInfo);
	const currentlyAllowedTrialCourses: AllowedTrialCoursesSelection = useAppSelector(selectCurrentlyAllowedTrialCourses);
	const currentlyAllowedFullCourses: AllowedFullCoursesSelection = useAppSelector(selectCurrentlyAllowedFullCourses);
	const { paymentMethods } = useAppSelector(getFullBillingState);
	const user = useAppSelector(selectUser);
	const subTrail = useAppSelector(selectIsTrial);
	const [selectedChapUnlockableIndex, setSelectedChapUnlockableIndex] = useState<number>(0);
	const [selectedChapIndex, setSelectedChapIndex] = useState<number>(0);
	const [selectedChapterId, setSelectedChapterId] = useState<number>(0);
	const [unlockChapterId, setUnlockChapterId] = useState<number | null>(null);
	const [payToUnlockModal, setPayToUnlockModal] = useState(false);
	const chapters: CourseChapter[] | null = trialCourses?.length !== 0 ? trialCourses[0].chapters! : null;

	const lockingChapter: CourseChapter | undefined = useMemo(
		() => chapters?.find(({ id }) => id === unlockChapterId),
		[chapters, unlockChapterId]
	);
	const unlockedChapters = useMemo(() => {
		if (chapters) {
			return chapters.filter(({ lockedChapter }) => !lockedChapter);
		}
		return null;
	}, [chapters]);

	useEffect(() => {
		if (chapterId) {
			const newIndex = unlockedChapters?.findIndex(({ id }) => id === Number(chapterId)) ?? 0;
			if (newIndex !== -1) {
				setSelectedChapterId(Number(chapterId));
			}
			setSelectedChapUnlockableIndex(newIndex);
		} else if (unlockedChapters?.length) {
			setSelectedChapterId(unlockedChapters[0].id);
		}
	}, [chapterId, unlockedChapters]);

	useEffect(() => {
		if (chapterId && chapters?.length) {
			const chapter = chapters?.find(({ id }) => id === +chapterId);
			if (chapter && chapter.lockedChapter) {
				setUnlockChapterId(+chapterId);
			}
		}
	}, [chapterId, chapters]);

	useEffect(() => {
		if (selectedChapterId && chapters) {
			const newIndex = chapters.findIndex(({ id }) => id === selectedChapterId);
			if (newIndex !== -1) {
				setSelectedChapIndex(newIndex);
			}
		}
	}, [selectedChapterId, chapters]);

	const selectedChapter = unlockedChapters && unlockedChapters[selectedChapUnlockableIndex];
	const refetch = useMemo(() => history.location.search.includes("refetch=true"), [history.location.search]);

	useEffect(() => {
		if (currentlyAllowedTrialCourses || currentlyAllowedFullCourses) {
			const courses = [...currentlyAllowedTrialCourses!, ...currentlyAllowedFullCourses!];
			const ids = courses.map(e => e.id);
			const isTrial = subTrail === true ? subTrail : !!currentlyAllowedTrialCourses?.length;
			if (ids && ids.length) {
				dispatch(loadTrialCourse({ ids, isTrial }));
			} else {
				dispatch(setLoading(false));
			}
		}
	}, [currentlyAllowedTrialCourses, currentlyAllowedFullCourses, refetch, subTrail, dispatch]);

	useEffect(() => {
		if (user && !introLessonsLoading && !user.hasTakenIntro) {
			dispatch(fetchIntroLessons());
		}
	}, [dispatch, user]);

	useEffect(() => {
		// fetch last viewed lesson id
		if (selectedChapterId) {
			dispatch(getLastViewedSectionId({ courseChapterId: selectedChapterId }));
		}
	}, [dispatch, selectedChapterId]);

	const latestLesson = useMemo(() => {
		if (lastViewedSectionId && unlockedChapters) {
			const foundLastView = unlockedChapters
				.flatMap(chapter => chapter.sections!)
				.flatMap(section => section.sectionLessons!)
				.find(lesson => lesson.id === lastViewedSectionId);
			if (foundLastView) {
				return foundLastView;
			}
		}

		const getLatestLessonWithSection = (chapter: CourseChapter) => {
			const allLessons = chapter.sections!.map(s => s.sectionLessons!).flat();

			const lockedLessonIndex = allLessons.findIndex(
				({ isLockedForCurrentUser, isLockedByPayment }) => !isLockedByPayment && isLockedForCurrentUser
			);
			const index = lockedLessonIndex === 0 ? lockedLessonIndex : lockedLessonIndex - 1;
			const latestLessonIndex = lockedLessonIndex === -1 ? allLessons.length - 1 : index;

			const latestLesson = allLessons[latestLessonIndex];
			const section = chapter.sections!.find(s => s.sectionLessons!.some(sl => sl.id == latestLesson.id));

			return { ...latestLesson, section };
		};

		return selectedChapter ? getLatestLessonWithSection(selectedChapter) : null;
	}, [unlockedChapters, lastViewedSectionId, selectedChapter]);

	const handlePayToUnlock = async () => {
		await dispatch(
			unlockChapter({
				stripe,
				chapterId: unlockChapterId!,
				isPaid: true,
				paymentProviderPaymentMethodIdentifier: paymentMethods?.id ?? "",
				paymentProviderId: 1
			})
		);
		setPayToUnlockModal(false);
		setUnlockChapterId(null);
	};

	if (isLoading) {
		return (
			<Box display="flex" alignItems="center" justifyContent="center" height={450} width="100%">
				<CircularProgress size="7rem" color="primary" thickness={5} variant="indeterminate" />
			</Box>
		);
	}

	if (!trialCourses.length) {
		return (
			<Box pt={12}>
				<Typography align="center" variant="h1">
					Sorry, nothing here yet
				</Typography>
			</Box>
		);
	}

	return (
		<Box>
			<Box height={"65vh"}>
				{latestLesson && (
					<CoursePreviewBackground
						title={latestLesson.lesson?.name}
						subtitle={latestLesson.section?.name}
						trailer={latestLesson.lesson?.trailer}
						lessonId={latestLesson.lessonId}
						sectionId={latestLesson.sectionId}
						totalTimeLengthInMinutes={latestLesson.lesson?.totalTimeLengthInMinutes}
						description={
							latestLesson.lesson?.description && latestLesson.lesson?.description !== "null"
								? latestLesson.lesson?.description
								: "No description provided yet"
						}
					/>
				)}
			</Box>
			<ChaptersRow>
				<LessonSwiperWrapper>
					<Swiper
						navigation
						slidesPerView="auto"
						spaceBetween={0}
						mousewheel={{ releaseOnEdges: true }}
						className="test"
					>
						{chapters?.map(({ id, mainImageUrl, mainImageThumbnailUrl, lockedChapter, data }, i) => (
							<SwiperSlide key={id}>
								<ChapterImgContainer
									onClick={() => {
										if (lockedChapter) {
											setUnlockChapterId(id);
											return;
										}
										setSelectedChapIndex(i);
										// select chapter index by unlockable chapters
										const index = unlockedChapters?.findIndex(ch => ch.id === id);
										if (index !== -1) {
											setSelectedChapUnlockableIndex(index as number);
										}
										setSelectedChapterId(id);
										history.push(`${pathname}?chapterId=${id}`);
									}}
								>
									{lockedChapter && <LockIcon />}
									<ChapterImg
										selected={i === selectedChapIndex}
										locked={lockedChapter}
										src={mainImageThumbnailUrl?.small250 || mainImageUrl || "https://picsum.photos/200/300"}
									/>
									<RemainingPeriod>{data.numberOfDays}</RemainingPeriod>
								</ChapterImgContainer>
							</SwiperSlide>
						))}
					</Swiper>
				</LessonSwiperWrapper>
			</ChaptersRow>
			<Box mb={10}>
				{selectedChapter?.sections!.map(section => (
					<LessonsList
						key={section.id}
						latestLesson={latestLesson}
						section={section}
						sectionLessons={section.sectionLessons}
					/>
				))}
			</Box>
			<TermsAndConditions
				open={!!user && !user.acceptedTermsAndConditions}
				onClose={isAgreed => {
					dispatch(confirmAcceptedTermsAndConditions(isAgreed));
				}}
			/>
			<UnlockChapterModal
				{...lockingChapter?.data}
				open={!!unlockChapterId}
				onClose={() => setUnlockChapterId(null)}
				loading={unlockLoading}
				onUnlock={async () => {
					const isPaid = !!lockingChapter?.data.isPaid;
					if (isPaid) {
						setPayToUnlockModal(true);
						return;
					}
					await dispatch(unlockChapter({ chapterId: unlockChapterId!, isPaid }));
					setUnlockChapterId(null);
				}}
			/>
			{payToUnlockModal && (
				<OneTimePayment
					height={"400px"}
					open={true}
					handleClose={() => {
						setPayToUnlockModal(false);
						dispatch(resetChapterUnlock());
					}}
					handlePayment={handlePayToUnlock}
					succeeded={!!isChapterSuccessfullyUnlocked}
					successBody={null}
					failed={isChapterSuccessfullyUnlocked !== null && !isChapterSuccessfullyUnlocked}
					onTryAgain={() => dispatch(resetChapterUnlock())}
					isLoading={unlockLoading}
					orderSummary={
						isChapterSuccessfullyUnlocked == null ? (
							<UnlockChapterOrderSummary
								items={[
									{
										id: 1,
										name: "Chapter",
										price: lockingChapter?.courseChapterDataItems[0].data.price || 0
									}
								]}
							/>
						) : null
					}
				/>
			)}
		</Box>
	);
};

export default CourseView;
