import React, { memo, useEffect, useMemo } from "react";

import { Accordion, AccordionDetails, AccordionSummary, Box, Typography, useTheme } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import Button from "@remar/shared/dist/components/Button";
import LogoComponent from "@remar/shared/dist/components/LogoComponent";
import { FullScreenLayout } from "@remar/shared/dist/layouts";
import { Course } from "@remar/shared/dist/models";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";
import { useHistory, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { getFullState, getLocationOpenSignupDetails } from "store/features/Auth/authSlice";

import { ClockSvg } from "assets/icons";

import { routes } from "core/constants";

import { LeftBlock, LessonNumber, RightBlock, Wrapper, useAccordionStyles } from "./styles";

// eslint-disable-next-line react/display-name
const SkeletonCourseExplanation = memo(() => {
	const theme = useTheme<IExtendedTheme>();
	const accordionClasses = useAccordionStyles();

	const mockCourse = {
		sections: [
			{ id: 1, sectionLessons: [{ id: 1 }, { id: 2 }] },
			{ id: 2, sectionLessons: [{ id: 11 }] }
		]
	};

	return (
		<>
			{mockCourse.sections.map(s => (
				<Box key={s.id}>
					<Skeleton variant="text" width="40%" />
					{s.sectionLessons.map((l, j) => (
						<Accordion key={l.id} classes={{ root: accordionClasses.discussionBoard }}>
							<AccordionSummary
								classes={{ root: accordionClasses.accSummary }}
								expandIcon={<ExpandMore fill={theme.palette.colors.basic[500]} />}
							>
								<Box display="flex" alignItems="center">
									<LessonNumber>
										<span>{j + 1}</span>
									</LessonNumber>
									<Skeleton variant="text" width={200} />
								</Box>
							</AccordionSummary>
							<AccordionDetails classes={{ root: accordionClasses.commentPost }}>
								<Typography className="lesson-description-text">
									<Skeleton variant="text" width="100%" />
								</Typography>
							</AccordionDetails>
						</Accordion>
					))}
				</Box>
			))}
		</>
	);
});

type CourseExplanationProps = {
	course: Course | null;
};
const CourseExplanation = ({ course }: CourseExplanationProps) => {
	const theme = useTheme<IExtendedTheme>();
	const accordionClasses = useAccordionStyles();

	const list = useMemo(() => course?.chapters!.flatMap(({ sections }) => sections?.map(s => s)), [course?.chapters]);

	if (!list) {
		return null;
	}

	return (
		<>
			{list.map((s, i) => (
				<Box key={s!.id}>
					<Typography>
						{i + 1}. {s?.name}
					</Typography>
					{s?.sectionLessons?.map((l, k) => (
						<Accordion key={l.id} classes={{ root: accordionClasses.discussionBoard }}>
							<AccordionSummary
								classes={{ root: accordionClasses.accSummary }}
								expandIcon={<ExpandMore fill={theme.palette.colors.basic[500]} />}
							>
								<Box display="flex" alignItems="center">
									<LessonNumber>
										<span>{k + 1}</span>
									</LessonNumber>
									<Typography className="lesson-header-text">{l?.lesson?.name}</Typography>
								</Box>
							</AccordionSummary>
							<AccordionDetails classes={{ root: accordionClasses.commentPost }}>
								<Typography className="lesson-description-text">{l?.lesson?.description}</Typography>
							</AccordionDetails>
						</Accordion>
					))}
				</Box>
			))}
		</>
	);
};

const InstitutionSignup = () => {
	const history = useHistory();

	const dispatch = useAppDispatch();
	const { locationId, courseId } = useParams<{ locationId: string; courseId: string }>();

	const { user, course, isLoading } = useAppSelector(getFullState);

	const location = user?.managedLocations[0];

	const displayPlaceholder = !course && isLoading;

	useEffect(() => {
		dispatch(getLocationOpenSignupDetails({ locationId, courseId }));
	}, [courseId, dispatch, locationId]);

	return (
		<FullScreenLayout>
			<Wrapper>
				<LeftBlock>
					{displayPlaceholder ? (
						<Skeleton variant="circle" width={140} height={50} />
					) : (
						<LogoComponent logo={location?.logoImageUrl} />
					)}
					<Typography className="title-text">
						<strong>Join to</strong>{" "}
						{displayPlaceholder ? <Skeleton variant="text" component="strong" /> : location?.name}
					</Typography>
					<Typography className="description-text">
						Unlock exclusive access to {course?.name} by joining {location?.name} — where knowledge meets opportunity!
						Dive into expert-led content and take your learning to the next level.
					</Typography>
					<Button
						fullWidth
						variant="filled"
						disabled={displayPlaceholder || !course}
						color="primary"
						onClick={() => history.push(`${routes.signUp.getPath()}/course/${course?.id}`)}
					>
						Join course
					</Button>
				</LeftBlock>
				<RightBlock>
					{!displayPlaceholder && !course ? (
						<Typography variant="h1" style={{ margin: "auto", textAlign: "center" }}>
							Sorry, current institution is closed for registration or course is not available
						</Typography>
					) : (
						<>
							<Typography className="about-text">About the course</Typography>
							<Typography className="course-title">
								{displayPlaceholder && <Skeleton variant="text" width="40%" />}
								{course?.name}
							</Typography>
							<Box className="duration-block">
								<ClockSvg width={18} height={18} />
								{displayPlaceholder && <Skeleton variant="text" width={40} />}
								<Typography>{course && Math.round(course.totalTimeLengthInMinutes)} min.</Typography>
							</Box>
							{displayPlaceholder && (
								<>
									<Skeleton variant="text" width="100%" />
									<Skeleton variant="text" width="60%" />
								</>
							)}
							<Typography className="course-description">{course?.description}</Typography>
							<Typography className="course-curriculum">Course curriculum</Typography>
							{displayPlaceholder ? <SkeletonCourseExplanation /> : <CourseExplanation course={course} />}
						</>
					)}
				</RightBlock>
			</Wrapper>
		</FullScreenLayout>
	);
};

export default InstitutionSignup;
